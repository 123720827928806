import { useEffect, useRef } from "react";
import gsap from "gsap-trial";

import LogoS from "../../../assets/images/logo-s.png";
import "./index.scss";

const Logo = () => {
  const bgRef = useRef();
  const outlineLogoRef = useRef();
  const solidLogoRef = useRef();

  return (
    <div className="logo-container" ref={bgRef}>
      <img
        className="solid-logo"
        ref={solidLogoRef}
        src={LogoS}
        alt="JavaScript,  Developer"
      />

      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="435.000000pt"
        height="487.000000pt"
        viewBox="0 0 435.000000 487.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,487.000000) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        ></g>
      </svg>
    </div>
  );
};

export default Logo;
