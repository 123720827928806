import { Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import About from "./components/About";
import Contact from "./components/Contact";
import Layout from "./components/Layout";
import "./App.scss";

function App() {
  return (
    <>
      <div className="hide">
        <h1>
          This site is not optimised for smaller screens yet. Please use a
          larger one
        </h1>
      </div>
      <div className="show">
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
          </Route>
        </Routes>
      </div>
    </>
  );
}

export default App;
